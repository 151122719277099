img:hover{
    cursor: pointer;
}
.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    background-color: #337ab7;
    color: #fff;
    border: 1px solid #2e6da4;
    cursor: pointer;
}

/* Style the label as a button */
.custom-file-upload:hover {
    background-color: #286090;
}

/* Style the label as a button when clicked */
.custom-file-upload:active {
    background-color: #1b4b72;
}

/* Hide the actual file input */
input[type="file"] {
    display: none;
}




