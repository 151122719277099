.LeftPane {
    width: 302px;
    height: 402px;
    float: left;
    border: 1px solid blueviolet;
    margin-right: 20px;
}
.RightPane {
    width: 602px;
    height: 402px;
    float: left;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 2px 10px 10px;
    border: 1px solid green;
}
.ColumnLeft {
    width: 30%;
}
.ColumnSecond {
    width: 70%;
}
table {
    font-size: 14px;
}